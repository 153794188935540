import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { omit } from 'lodash';
import { NzMessageService } from 'ng-zorro-antd/message';
import { catchError, concatMap, distinctUntilChanged, exhaustMap, filter, firstValueFrom, map, merge, of, switchMap, tap } from 'rxjs';
import { DocumentClass } from 'src/app/graphql/frontend-data-graphql';

import { DocumentClassApiActions, DocumentClassUiActions } from './document-class.actions';
import { selectSelectedIds, selectSubtreeRoot } from './document-class.reducer';
import { DocumentClassService } from './document-class.service';
import { intersectDocumentClasses } from './util/batch.utils';

@Injectable()
export class DocumentClassEffects {
  constructor(
    private actions$: Actions,
    private msgSrv: NzMessageService,
    private translateSrv: TranslateService,
    private store: Store,
    private documentClassSrv: DocumentClassService,
    private titleSrv: Title,
    private router: Router
  ) {}

  loadTreeOnSubtreeRootChange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentClassApiActions.findSubTreeRootSucceeded),
      map(({ item }) => DocumentClassUiActions.findManyChildrenTriggered({ parentId: item?.identifier ?? null, clearBeforehand: true }))
    );
  });

  loadSubtreeRoot$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentClassUiActions.subtreeRootChanged),
      map(({ rootId }) => DocumentClassUiActions.findSubTreeRootTriggered({ identifier: rootId }))
    );
  });

  findWithinTree$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentClassUiActions.findManyChildrenTriggered),
      switchMap(({ parentId }) => {
        return this.documentClassSrv.findMany({ parentId: { eq: parentId } }, { withDetail: false, pageSize: 750 }).pipe(
          map(({ data, errors }) =>
            !errors?.length
              ? DocumentClassApiActions.findManyChildrenSucceeded({
                  parentId,
                  items: data!.documentClasses.edges.map(e => e.node) as DocumentClass[]
                })
              : DocumentClassApiActions.requestFailed({ errors: errors ?? [] })
          ),
          catchError(err => of(DocumentClassApiActions.requestFailed({ errors: [err] })))
        );
      })
    );
  });

  findSubtreeRoot$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentClassUiActions.findSubTreeRootTriggered),
      switchMap(({ identifier }) => {
        if (identifier === null) {
          return of(
            DocumentClassApiActions.findSubTreeRootSucceeded({
              item: null
            })
          );
        } else {
          return this.documentClassSrv.findOne(identifier!).pipe(
            map(({ data, errors }) =>
              !errors?.length
                ? DocumentClassApiActions.findSubTreeRootSucceeded({
                    item: data!.documentClass as DocumentClass
                  })
                : DocumentClassApiActions.requestFailed({ errors: errors ?? [] })
            ),
            catchError(err => of(DocumentClassApiActions.requestFailed({ errors: [err] })))
          );
        }
      })
    );
  });

  findSearchResults$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentClassUiActions.searchTriggered),
      map(({ q }) => q.toLowerCase().trim()),
      distinctUntilChanged(),
      switchMap(async token => {
        if (!token) {
          return DocumentClassApiActions.searchSucceeded({ q: '', items: [] });
        }
        const items = await firstValueFrom(
          this.documentClassSrv
            .search(token)
            .pipe(map(doc => doc.data.documentClassesBySearchToken.edges.map(e => e.node as DocumentClass)))
        );
        return DocumentClassApiActions.searchSucceeded({ q: token, items });
      }),
      catchError(err => of(DocumentClassApiActions.requestFailed({ errors: [err] })))
    );
  });

  observe$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentClassUiActions.findManyChildrenTriggered),
      filter(({ clearBeforehand }) => clearBeforehand === true),
      switchMap(({ parentId }) => {
        const filter = { identifier: { like: `${parentId ?? ''}%` } };
        const created$ = this.documentClassSrv
          .observeCreated(filter) // filter only parent descendants (incl. root node)
          .pipe(map(result => DocumentClassApiActions.created({ item: result.data!.createdDocumentClass })));
        const updated$ = this.documentClassSrv
          .observeUpdated(filter)
          .pipe(map(result => DocumentClassApiActions.updatedOne({ item: result.data!.updatedOneDocumentClass })));
        const deleted$ = this.documentClassSrv
          .observeDeleted(filter)
          .pipe(map(result => DocumentClassApiActions.deletedOne({ item: result.data!.deletedOneDocumentClass as DocumentClass })));

        return merge(created$, updated$, deleted$);
      })
    );
  });

  create$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentClassUiActions.createTriggered),
      concatMap(({ input }) =>
        this.documentClassSrv.create(input).pipe(
          map(({ data, errors }) =>
            data
              ? DocumentClassApiActions.mutationSucceeded({ identifiers: [data.createOneDocumentClass.identifier], mode: 'create' })
              : DocumentClassApiActions.requestFailed({ errors: errors ?? [] })
          ),
          catchError(err => of(DocumentClassApiActions.requestFailed({ errors: [err] })))
        )
      )
    );
  });

  createBatch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentClassUiActions.createBatchTriggered),
      concatMap(({ input, parentIds }) =>
        this.documentClassSrv.createMany(input, parentIds).pipe(
          map(({ data, errors }) =>
            data
              ? DocumentClassUiActions.selectionChanged({ identifier: data.createDocumentClassBatch.identifiers })
              : DocumentClassApiActions.requestFailed({ errors: errors ?? [] })
          ),
          catchError(err => of(DocumentClassApiActions.requestFailed({ errors: [err] })))
        )
      )
    );
  });

  updateOne$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentClassUiActions.updateOneTriggered),
      concatMap(p =>
        this.documentClassSrv.updateOne(p.identifier, p.update).pipe(
          map(({ data, errors }) =>
            data
              ? DocumentClassApiActions.mutationSucceeded({ identifiers: [data.updateOneDocumentClass.identifier], mode: 'update' })
              : DocumentClassApiActions.requestFailed({ errors: errors ?? [] })
          ),
          catchError(err => of(DocumentClassApiActions.requestFailed({ errors: [err] })))
        )
      )
    );
  });

  updateBatch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentClassUiActions.updateBatchTriggered),
      concatMap(({ update, identifiers }) =>
        this.documentClassSrv.updateMany(identifiers, update).pipe(
          map(({ data, errors }) =>
            data
              ? DocumentClassApiActions.mutationSucceeded({ identifiers: data.updateDocumentClassBatch.identifiers, mode: 'update' })
              : DocumentClassApiActions.requestFailed({ errors: errors ?? [] })
          ),
          catchError(err => of(DocumentClassApiActions.requestFailed({ errors: [err] })))
        )
      )
    );
  });

  deleteSubTree$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentClassUiActions.deleteOneTriggered),
      concatMap(p =>
        this.documentClassSrv.deleteOne(p.identifier).pipe(
          map(({ data, errors }) =>
            data
              ? DocumentClassApiActions.mutationSucceeded({ identifiers: [data.deleteOneDocumentClass.identifier], mode: 'delete' })
              : DocumentClassApiActions.requestFailed({ errors: errors ?? [] })
          ),
          catchError(err => of(DocumentClassApiActions.requestFailed({ errors: [err] })))
        )
      )
    );
  });

  deleteSubTreeBatch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentClassUiActions.deleteBatchTriggered),
      concatMap(p =>
        this.documentClassSrv.deleteMany(p.identifiers).pipe(
          map(({ data, errors }) =>
            data
              ? DocumentClassApiActions.mutationSucceeded({ identifiers: data.deleteDocumentClassBatch.identifiers, mode: 'delete' })
              : DocumentClassApiActions.requestFailed({ errors: errors ?? [] })
          ),
          catchError(err => of(DocumentClassApiActions.requestFailed({ errors: [err] })))
        )
      )
    );
  });

  restoreSubTree$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentClassUiActions.restoreOneTriggered),
      concatMap(p =>
        this.documentClassSrv.restoreOne(p.identifier).pipe(
          map(({ data, errors }) =>
            data
              ? DocumentClassApiActions.mutationSucceeded({ identifiers: [data.restoreOneDocumentClass.identifier], mode: 'update' })
              : DocumentClassApiActions.requestFailed({ errors: errors ?? [] })
          ),
          catchError(err => of(DocumentClassApiActions.requestFailed({ errors: [err] })))
        )
      )
    );
  });

  restoreSubTreeBatch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentClassUiActions.restoreBatchTriggered),
      concatMap(p =>
        this.documentClassSrv.restoreMany(p.identifiers).pipe(
          map(({ data, errors }) =>
            data
              ? DocumentClassApiActions.mutationSucceeded({ identifiers: data.restoreDocumentClassBatch.identifiers, mode: 'update' })
              : DocumentClassApiActions.requestFailed({ errors: errors ?? [] })
          ),
          catchError(err => of(DocumentClassApiActions.requestFailed({ errors: [err] })))
        )
      )
    );
  });

  reorderOne$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentClassUiActions.reorderTriggered),
      concatMap(({ identifier, idx }) =>
        this.documentClassSrv.reorder(identifier, idx).pipe(
          map(({ data, errors }) =>
            data
              ? DocumentClassApiActions.mutationSucceeded({ identifiers: [data.reorderDocumentClass.identifier], mode: 'update' })
              : DocumentClassApiActions.requestFailed({ errors: errors ?? [] })
          ),
          catchError(err => of(DocumentClassApiActions.requestFailed({ errors: [err] })))
        )
      )
    );
  });

  duplicateSubTree$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentClassUiActions.duplicateOneTriggered),
      concatMap(({ input, referenceId }) =>
        this.documentClassSrv.duplicate(referenceId, input).pipe(
          map(({ data, errors }) =>
            data
              ? DocumentClassApiActions.mutationSucceeded({
                  identifiers: [data.duplicateDocumentClassTree.identifiers.sort().at(0)!],
                  mode: 'create'
                })
              : DocumentClassApiActions.requestFailed({ errors: errors ?? [] })
          ),
          catchError(err => of(DocumentClassApiActions.requestFailed({ errors: [err] })))
        )
      )
    );
  });

  prefillOnSelectionChanged$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentClassUiActions.selectionChanged),
      switchMap(({ identifier }) => {
        if (identifier.length > 0) {
          return this.documentClassSrv.findMany({ identifier: { in: identifier } }, { withSettings: true, withDetail: true }).pipe(
            map(({ data, errors }) => {
              return data
                ? DocumentClassApiActions.prefillFormSucceeded({
                    value: intersectDocumentClasses(
                      data.documentClasses.edges.map(e => e.node) as DocumentClass[]
                    ) as Partial<DocumentClass>,
                    mode: 'update',
                    selectedIds: identifier
                  })
                : DocumentClassApiActions.requestFailed({ errors: errors ?? [] });
            }),
            catchError(err => of(DocumentClassApiActions.requestFailed({ errors: [err] })))
          );
        } else {
          return of(DocumentClassApiActions.prefillFormSucceeded({ value: {}, mode: 'update', selectedIds: [] }));
        }
      })
    );
  });

  prefillOnAdd$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentClassUiActions.add),
      concatLatestFrom(() => [this.store.select(selectSelectedIds), this.store.select(selectSubtreeRoot)]),
      exhaustMap(async ([_, selectedIds, subtreeRootClass]) => {
        const parentIds = selectedIds.length ? selectedIds : subtreeRootClass ? [subtreeRootClass.identifier] : [];
        const data = await this.documentClassSrv.buildCreateInput(parentIds);
        return DocumentClassApiActions.prefillFormSucceeded({
          value: data as DocumentClass,
          mode: 'create',
          selectedIds
        });
      }),
      catchError(err => of(DocumentClassApiActions.requestFailed({ errors: [err] })))
    );
  });

  prefillOnDuplicate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentClassUiActions.duplicate),
      concatLatestFrom(() => [this.store.select(selectSelectedIds), this.store.select(selectSubtreeRoot)]),
      exhaustMap(async ([_, selectedIds, subtreeRootClass]) => {
        const originId = (selectedIds.length ? selectedIds : [subtreeRootClass!.identifier]).at(0);
        const res = await firstValueFrom(this.documentClassSrv.findOne(originId!));
        const item = res.data.documentClass as DocumentClass;
        const copyPostfix = this.translateSrv.instant('ADMIN.DOCUMENT-CLASSES.DOCUMENT-CLASS-ACTION.DUPLICATE.copy');
        const data = {
          ...omit(item, 'created', 'updated'),
          displayName: `${item.displayName}_${copyPostfix}`,
          identifier: `${item.identifier}_${copyPostfix?.toLowerCase()}`,
          localIdentifier: `${item.localIdentifier}_${copyPostfix?.toLowerCase()}`
        } as DocumentClass;
        return DocumentClassApiActions.prefillFormSucceeded({
          value: data as DocumentClass,
          mode: 'duplicate',
          selectedIds: [originId!]
        });
      }),
      catchError(err => of(DocumentClassApiActions.requestFailed({ errors: [err] })))
    );
  });

  reloadSelectedAfterUpdate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentClassApiActions.mutationSucceeded),
      filter(({ mode, identifiers }) => mode === 'update' && identifiers.length === 1),
      exhaustMap(async ({ identifiers }) => {
        const [identifier] = identifiers;
        const { data, errors } = await firstValueFrom(this.documentClassSrv.findOne(identifier));
        if (errors) {
          return DocumentClassApiActions.requestFailed({ errors });
        } else {
          return DocumentClassApiActions.prefillFormSucceeded({
            value: data.documentClass as DocumentClass,
            mode: 'update',
            selectedIds: [identifier]
          });
        }
      }),
      catchError(err => of(DocumentClassApiActions.requestFailed({ errors: [err] })))
    );
  });

  // Non-dispatching effects:

  onCreateOrUpdateSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DocumentClassApiActions.mutationSucceeded),
        filter(({ mode }) => mode === 'create' || mode === 'update'),
        concatLatestFrom(() => this.store.select(selectSubtreeRoot)),
        tap(([{ identifiers }, subTreeRoot]) => {
          this.msgSrv.info(this.translateSrv.instant('ADMIN.DOCUMENT-CLASSES.DOCUMENT-CLASS-ACTION.UPDATE.success'));
          const url = this.router.url.split('?').at(0) ?? '';
          if (url.startsWith('/admin/document-class-fs')) {
            this.router.navigate(
              ['/admin/document-class-fs', subTreeRoot?.identifier ?? 'null', 'preview', identifiers.length > 1 ? 'batch' : identifiers[0]],
              { queryParams: { batchDocumentClassIds: identifiers.length > 1 ? identifiers : [] } }
            );
          }
        })
      );
    },
    { dispatch: false }
  );

  onDeleteSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DocumentClassApiActions.mutationSucceeded),
        filter(({ mode }) => mode === 'delete'),
        concatLatestFrom(() => this.store.select(selectSubtreeRoot)),
        tap(([{ identifiers }, subTreeRoot]) => {
          const [identifier] = identifiers;
          const parentId = identifier.split('/').slice(0, -1).join('/') || null;
          const url = this.router.url.split('?').at(0) ?? '';
          this.msgSrv.info(this.translateSrv.instant('ADMIN.DOCUMENT-CLASSES.DOCUMENT-CLASS-ACTION.UPDATE.success'));
          if (url.startsWith('/admin/document-class-fs')) {
            this.router.navigate([
              '/admin/document-class-fs',
              subTreeRoot?.identifier ?? 'null',
              ...(parentId ? ['preview', parentId] : [])
            ]);
          }
        })
      );
    },
    { dispatch: false }
  );

  onError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DocumentClassApiActions.requestFailed),
        tap(({ errors }) => {
          console.error(errors);
          this.msgSrv.error(this.translateSrv.instant('COMMON.ERROR.unexpected'));
        })
      );
    },
    { dispatch: false }
  );

  updateTitleAndUrl$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DocumentClassApiActions.prefillFormSucceeded),
        concatLatestFrom(() => [this.store.select(selectSubtreeRoot)]),
        tap(([{ value, selectedIds, mode }, subtreeRoot]) => {
          if (mode !== 'update' || !value.displayName) {
            this.titleSrv.setTitle(this.translateSrv.instant('DOCUMENT.CLASSIFICATION.documentClasses'));
          } else {
            this.titleSrv.setTitle(value.displayName);
          }

          if (mode === 'create' && !selectedIds.length) {
            this.router.navigate(['/admin/document-class-fs', subtreeRoot?.identifier ?? 'null', 'preview', 'null']);
          }
        })
      );
    },
    { dispatch: false }
  );
}
